<template>
  <b-modal v-model="active" title="Update VIN" @hide="closeModalVinUpdate" hide-footer>
    <validation-observer v-slot="{handleSubmit}">
      <b-overlay :show="updateStatus.overlay" rounded="sm">
        <form @submit.prevent="handleSubmit(preUpdateVin)">
          <validation-provider name="VIN" rules="required|vin" ref="vinValidator" v-slot="{errors}">
            <b-row class="my-1" no-gutters>
              <b-col sm="4"><label>New VIN:</label></b-col>
              <b-col sm="8">
                <b-input id="vinbox" v-model="newVinUpdate.vin" style="width: 100%;" type="text"/>
                <span class="error_field" v-if="errors.length">{{ $t(`vue_validator.${errors[0]}`) }}</span>
                <span class="error_field" v-if="updateStatus.error">VIN could not be verified</span>
                <br>
                <span class="error_field"
                      v-if="updateStatus.errorExist">There is another order with this VIN</span>
              </b-col>
            </b-row>
          </validation-provider>
          <b-row v-if="!updateStatus.error">
            <b-col>
              <b-button block variant="primary" type="submit" class="mt-2">UPDATE VIN</b-button>
            </b-col>
          </b-row>
        </form>
        <b-row v-if="updateStatus.error">
          <b-col>
            <b-button @click="closeModalVinUpdate" block variant="primary" class="mt-2">GO BACK</b-button>
          </b-col>
          <b-col>
            <b-button block variant="danger" @click="updateVin" class="mt-2">UPDATE ANYWAY</b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </validation-observer>

  </b-modal>
</template>

<script>
import {get, post} from "@/services/api";

export default {
  name: "UpdateVin",
  data() {
    return {
      active: false,
      order: null,
      newVinUpdate: {
        vin: null,
        verified: false
      },
      updateStatus: {
        overlay: false,
        error: false,
        errorExist: false,
        noVerifiedSaveAnyWay: false,
      }
    };
  },
  methods: {
    open(order) {
      this.active = true;
      this.order = order;
    },
    async preUpdateVin() {
      this.newVinUpdate.verified = false
      this.updateStatus.error = false
      this.updateStatus.errorExist = false
      if (this.newVinUpdate.vin !== null && this.newVinUpdate.vin !== '') {
        this.$swal({
          showCancelButton: true,
          showConfirmButton: true,
          html: `Are you sure the VIN: <br> <h3 class="text-danger mt-3">${this.newVinUpdate.vin}</h3> is correct?`,
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000',
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        })
          .then(async res => {
            if (res.isConfirmed) {
              this.updateStatus.overlay = true
              const responseVin = await this.findVin()
              if (responseVin === null || responseVin === "") {
                post('site/find-by-vin', {vin: this.newVinUpdate.vin.toUpperCase()}).then((async (data) => {
                  this.waitResponse()
                })).catch(() => {
                  this.updateStatus.overlay = false
                  this.updateStatus.error = true
                });
              } else {
                this.updateStatus.overlay = false
                this.updateStatus.errorExist = true
                setTimeout(() => {
                  this.updateStatus.errorExist = false
                }, 3000)
              }
            }
          });
      }
    },
    closeModalVinUpdate() {
      this.$bvModal.hide('modal-update-vin')
      this.updateStatus = {
        overlay: false,
        error: false,
        errorExist: false,
        noVerifiedSaveAnyWay: false,
      }
    },
    async updateVin() {
      const {data} = await post("order-software/update-vin", {
        id: this.order.id,
        vin: this.newVinUpdate.vin,
        verified: this.newVinUpdate.verified
      }, true)
      if (data === 200) {
        this.$swal({
          icon: 'success',
          title: 'VIN Updated',
          showConfirmButton: false,
          timer: 1500
        })
        this.$emit('updated-vin', this.newVinUpdate.vin)
        this.active = false;
      }
    },
    async findVin() {
      const {data} = await get(`order-software/find-order-vin?vin=${this.newVinUpdate.vin}&id=${this.order.id}`, null, true)
      return data
    },
    waitResponse() {
      this.waitTimeOut = setTimeout(() => {
        this.sockets.unsubscribe(this.newVinUpdate.vin.toUpperCase())
        this.updateStatus.overlay = false
        this.updateStatus.error = true
        this.updateStatus.errorExist = false
      }, 25 * 1000)
      this.sockets.subscribe(this.newVinUpdate.vin.toUpperCase(), async (response) => {
        if (this.waitTimeOut) {
          clearTimeout(this.waitTimeOut);
        }
        if (response.result) {
          this.newVinUpdate.verified = true
          await this.updateVin()
        } else {
          this.updateStatus.error = true
          this.updateStatus.errorExist = false
        }
        this.updateStatus.overlay = false
      });
    },
  }
}
</script>

<style scoped>

</style>
